<template>
    <span v-if="schools">
        {{ uuid | schoolLabel(schools) }}
    </span>
</template>

<script>
export default {
    name: 'SchoolLabel',
    props: {
        uuid: {
            type: String,
            required: true
        }
    },
    filters: {
        schoolLabel(uuid, schools) {
            return schools.find(o => o.uuid === uuid).title
        }
    },
    computed: {
        schools() {
            return this.$store.getters['school/schools']
        }
    }
}
</script>

<style lang=scss>

</style>
